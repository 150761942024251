// import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import styled from 'styled-components'
import { ShadowText } from 'components/styled/typography'
import { SCREEN } from 'styles/screens'
import LinkedIn from 'svgs/linkedin.svg'
import { getImageUrl } from '../../Utils'
import { getTranslation } from '../../data/dictionary'
import LearningFromIllustration from '../../svgs/newDesigns/Group-2.svg'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  min-height: 800px;
  margin-bottom: 60px;
  p.text-muted {
    a {
      width: 0;
    }
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  p {
    font-size: 25px;
    &.text-muted {
      font-size: 22px;
    }
  }
  .social {
    height: 20px;

    svg {
      margin-left: 0.5rem;

      path {
        transition: all 0.3s;
        fill: var(--primary);
      }

      &:hover {
        path {
          fill: var(--secondary);
        }
      }
    }
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    h4,
    .h4 {
      font-size: 20px;
    }
    p.lead {
      font-size: 16px;
    }
    p.title {
      font-size: 17px;
    }
    p.text-muted {
      font-size: 16px;
      a {
        width: unset;
      }
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1520px;
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: wrap;

  > div {
    margin-bottom: 20px;
  }

  h6 {
    font-weight: 400;
    line-height: 1.8;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    flex-direction: column;
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const ColumnHalf = styled.div`
  flex: 0 0 47%;
  max-width: 47%;
  &:nth-child(odd) {
    
  }

  .innerRow {
    margin-top: 40px;
    margin-left: 0;
    margin-right: 0;
     padding-left: 15px;
    h4,
    .h4 {
      margin: 2.4rem 0 1.38rem;
      font-size: 2.25rem;
      font-weight: bold;
      line-height: 1.2;
      text-decoration: none;
      color: #000;
      width: 100%;
    }
  }

  .innerColumn {
    margin: 0;
    flex: 0 0 47%;
    max-width: 47%;
    .author {
      margin-right: 30px;
      width: 120px;
      height: 120px;
      border-radius: 100%;
    }

    .companyWrapper {
      width: 66px;
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;

      .company {
        object-fit: cover;
        max-width: 33px;
        max-height: 33px;
      }
    }
  }

  > ul li {
    display: flex;
    align-items: start;
    font-size: 23px;
    margin-top: 26px;

    > div {
      margin-right: 1rem;
      margin-top: 11px;
    }
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    &:last-child {
      margin-left: 0;
    }
    .author {
      width: 110px;
      height: 110px;
      border-radius: 100%;
    }
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    .innerRow {
      margin-top: 20px;
    }
    h4,
    .h4 {
      font-size: 20px !important;
      margin-top: 20px;
      margin-bottom: 22px;
    }
    .innerColumn {
      .author {
        width: 80px;
        height: 80px;
        margin-right: 15px;
      }
      min-width: 100%;
      flex: 0 0 100%;
      display: flex;
      margin-bottom: 30px;
    }
  }
`

const links = title => {
  let mapper = {
    'Product Management Pro': '/program/product-management/',
    'Product Design Pro': '/program/product-design/',
    'Digital Marketing Level Up': '/program/digital-marketing/',
    'B2B Sales Level Up': '/program/b2b-sales/',
    'Advanced Full-Stack Development': '/program/full-stack/',
    'Advanced Back-End Development': '/program/back-end/',
    'Advanced Front-End Development': '/program/front-end/',
  }

  return mapper[title]
}

const LearningFrom = ({ programs }) => {
  return (
    <div>
      <div className="secondary-container">
        <ShadowText highlight>
          <div className="white-highlighted-title">
            <h3 className="ml-5">
              {getTranslation(
                'who_will_you_learn_from_heading',
                "Who you'll be learning from?",
                false,
              )}
            </h3>
          </div>
        </ShadowText>
        {/* <StaticImage
          src="../../svgs/newDesigns/learningFrom.svg"
          alt="workspace"
          layout="constrained"
          className="mt-auto  mb-auto mx-auto"
          placeholder="tracedSVG"
        /> */}
        <LearningFromIllustration className="learning-from-illustration" />
      </div>
      <Container>
        <FixedContainer>
          <DescriptionContainer>
            <Row>
              <p className="lead">
                {getTranslation(
                  'who_will_you_learn_from_description',
                  "We created a balanced system so that our Students can receive support in many dimensions. Our educational programs are designed by International Tutors with the help of Armenian Deans, who understand the local context in Armenia. ",
                  true,
                )}
              </p>
             
              {programs.map(program => {
                const { data } = program
                if (!data.lead.companyLogos) {
                  data.lead.companyLogos = []
                }
                if (!data.dean.companyLogos) {
                  data.dean.companyLogos = []
                }
                return (
                  <ColumnHalf>
                    <Row className="innerRow">
                      <a className="h4" href={links(data.home_title)}>
                        {data.home_title}
                      </a>
                      <ColumnHalf className="innerColumn">
                        <img
                          src={getImageUrl(data.lead.image)}
                          className="author"
                          alt=""
                        />
                        <div>
                          <p className="title mb-0 mt-0 lh-1">{getTranslation("tutor", "", true)}</p>
                          <p className="text-muted mt-0 mb-0 lh-1 d-flex align-items-center">
                            {data.lead.name}
                            <a
                              href={data.lead.linkedin}
                              target="_blank"
                              className="social"
                            >
                              <LinkedIn />
                            </a>
                          </p>
                          <div className="d-flex flex-wrap">
                            {data.lead.companyLogos.map(logo => {
                              return (
                                <div className="companyWrapper">
                                  <img
                                    className="company"
                                    src={getImageUrl(logo.image)}
                                    alt=""
                                  />
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </ColumnHalf>
                      <ColumnHalf className="innerColumn">
                        <img
                          src={getImageUrl(data.dean.image)}
                          className="author"
                          alt=""
                        />
                        <div>
                          <p className="title mb-0 mt-0 lh-1">{getTranslation("dean", "", true)}</p>
                          <p className="text-muted mt-0 mb-0 lh-1 d-flex align-items-center">
                            {data.dean.name}
                            <a
                              href={data.dean.linkedin}
                              target="_blank"
                              className="social"
                            >
                              <LinkedIn />
                            </a>
                          </p>

                          <div className="d-flex flex-wrap">
                            {data.dean.companyLogos.map(logo => {
                              return (
                                <div className="companyWrapper">
                                  <img
                                    className="company"
                                    src={getImageUrl(logo.image)}
                                    alt=""
                                  />
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </ColumnHalf>
                    </Row>
                  </ColumnHalf>
                )
              })}
            </Row>
          </DescriptionContainer>
        </FixedContainer>
      </Container>
    </div>
  )
}

export default LearningFrom
