import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { ShadowText } from 'components/styled/typography'
import { Button } from 'components/styled/button'
import ArrowIcon from 'svgs/arrow_top_right.svg'

import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'
import { useEffect, useState } from 'react'
import fetch from 'node-fetch'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  min-height: 800px;
  .apply-menu {
    z-index: 1000000;
  }
  .ms-15px {
    margin-left: 15px;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`
const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1520px;

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    // flex-direction: column;
    align-items: flex-start;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    // flex-direction: column-reverse;
  }
`

const DescriptionContainer = styled.div`
  flex: 1 1 49%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > h5 {
    margin-top: 0;
    max-width: 550px;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.7;

    > a {
    font-size: inherit !important;
    color: inherit !important;
  }
    > span {
      background-color: rgba(236, 251, 112, 1);
    }

  }

  
  > button {
    margin-right: 12px;
    > svg {
      margin-left: 14px;
    }
  }
  small {
    max-width: 400px;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    h5 {
      font-size: 18px;
      max-width: 100%;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    order: 2;
    > p:first-child {
      margin-top: 0;
    }
    small {
      max-width: 270px;
    }
  }
`

const ImageContainer = styled.div`
  margin-top: 62px;
  flex: 1 1 49%;
  margin-left: 1%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;

  > div {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: -20px;
      left: -22px;
      height: 65%;
      width: 65%;
      border: 3px solid var(--primary);
      z-index: -1;
    }
    &:after {
      content: '';
      position: absolute;
      right: -22px;
      top: -15px;
      height: 65%;
      width: 65%;
      background-color: var(--secondary);
      z-index: -1;
    }
  }
  &.d-md-none {
    display: none;
  }
  &.d-md-block {
    display: flex;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-top: 0;
    margin-bottom: 62px;

    &.d-md-none {
      display: flex;
    }
    &.d-md-block {
      display: none;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    order: -1;
    margin: 24px;
    margin-bottom: 56px;
    padding-right: 0;
  }
`

const SelectionSection = () => {

  const [programs, setPrograms] = useState([]);

  useEffect(async () => {
    fetch(`https://backend.buildup.am/programs`).then(response => response.json()).then(response=>{
      setPrograms(response.filter(program => program.enabled))
    })
  },[]);

  return <Container>
    <FixedContainer>
      <DescriptionContainer>
        <ShadowText highlight>
          <h3 className="mw-max-content">
            {getTranslation('Selection Process', 'Selection Process')}
          </h3>
        </ShadowText>
        <ImageContainer className="d-md-none">
          <div>
            <StaticImage
              src="../../images/172v 1.jpg"
              alt="meeting"
              layout="constrained"
              placeholder="tracedSVG"
            />
          </div>
        </ImageContainer>
        <h5>
           {getTranslation('selection_process_description', "", true)}
          
        </h5>

        {/* <div className="position-relative open-menu-on-hover">
          <Button
            secondary
            onClick={e => {
              window.location.href = '/program'
            }}
          >
            Apply here <ArrowIcon className="ms-15px" />
          </Button>
          <div
            className="position-absolute apply-menu left-0 d-none"
            id="apply-menu"
          >
            <ul>
              {programs.map(program => {
                return <li key={program.key}>
                  <a href={`/program/${program.key}`}>
                    {program.data.title}
                  </a>
                </li>
              })}
            </ul>
          </div>
        </div> */}
      </DescriptionContainer>
      <ImageContainer className="d-none d-md-block">
        <div>
          <StaticImage
            src="../../images/172v 1.jpg"
            alt="meeting"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </div>
      </ImageContainer>
    </FixedContainer>
  </Container>
}

export default SelectionSection
