import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import styled from 'styled-components'
import { SCREEN } from 'styles/screens'
import { SecondaryButtonLink } from 'components/styled/button'
import { Card } from 'components/styled/card'
import { ShadowText } from 'components/styled/typography'
// import GameChangerPDF from '../../svgs/newDesigns/gameChangerPdf.svg'
// import GameChanger from '../../images/gameChanger.png'
import SettingsFolder from '../../svgs/newDesigns/settingsFolder.svg'
import Community from '../../svgs/newDesigns/community.svg'
import Elearning from '../../svgs/newDesigns/elearning.svg'
import { getTranslation } from '../../data/dictionary'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
  svg:not(.gamechanger) {
    max-width: 80px;
    max-height: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  .mt-5 {
    margin-top: 30px;
    font-weight: bold;
  }
  // .gatsby-image-wrapper > div {
  //   max-width: 80px !important;
  // }
  // source + img {
  //   object-fit: contain !important;
  // }
  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-3rem {
    padding-top: 3rem;
  }

  .pb-100px {
    padding-bottom: 100px;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    .pb-100px {
      padding-bottom: 30px;
    }
    .mt-5 {
      margin-top: 12px;
    }
    .pt-3rem {
      padding-top: 1.5rem;
    }
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1480px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  // margin-left: auto;
  // margin-right: auto;
`

const Column = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 1 0 0%;

  a,
  a > h6 {
    color: rgb(0, 0, 0);
    text-decoration: none;
  }

  &.mw-771px h6 {
    max-width: 771px !important;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

const ColumnFour = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 25%;
  max-width: 25%;
  .gameChanger {
    > div {
      max-width: 250px !important;
    }
    source + img {
      object-fit: contain !important;
    }
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

const CardColumn = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    padding-top: 15px;
    padding-bottom: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

const StyledCard = styled(Card)`
  height: 100%;
  position: relative;
  padding: 40px 48px 18px 48px;
  width: 100%;
  text-align: center;

  > h5 {
    font-weight: 700;
    margin-bottom: 0;
  }
  > p {
    font-weight: 500;
  }
`

const GameChangerSection = () => {
  return (
    <div>
      <div className="page">
        <Container className="container">
          <FixedContainer>
            <ShadowText highlight>
              <h3>{
                  getTranslation("become-game-changer", "", true)
                }</h3>
            </ShadowText>
            <Row className="pt-3rem pb-100px">
              {/* <DescriptionContainer> */}
              <Column className="pr-0 pl-0 mw-771px">
                <div>
                  <h6>
                    {getTranslation('game_changer_description_1','BuildUp Bootcamp is a great place for accelerating\n' +
                      '                    professional development for the middle-level specialists\n' +
                      '                    from Tech-Industry and going from outsource-oriented work to\n' +
                      '                    a product-oriented.')}
                  </h6>
                  <h6>
                    {getTranslation('game_changer_description_2','Our comprehensive educational methodology is created that\n' +
                      '                    way so you can learn from top-level international tutors,\n' +
                      '                    grow your network worldwide, and find like-minded\n' +
                      '                    professionals to collaborate with, all while solving a real\n' +
                      '                    user pain point by creating a digital product.')}
                  </h6>
                  <h6>
                    {getTranslation('game_changer_translation_3','We use problem-based education, which means that everything\n' +
                      '                    you learn is used in practice. No boring theoretical\n' +
                      '                    lectures that are forgotten in days, you will apply the\n' +
                      '                    knowledge to solve a problem immediately as you learn.')}
                  </h6>
                </div>
              </Column>
              {/* </DescriptionContainer> */}
              <ColumnFour className="d-flex flex-column pl-0">
                {/* <GameChangerPDF className="mx-auto gamechanger" /> */}
                <StaticImage
                  src="../../images/gameChanger.png"
                  alt="workspace"
                  layout="constrained"
                  className="mt-auto mb-auto d-tablet-none gameChanger"
                  placeholder="tracedSVG"
                />

                <SecondaryButtonLink
                  href="https://d34rq7yialevbd.cloudfront.net/uploads/Methodology_of_BuildUp.pdf"
                  target="_blank"
                  download
                  className="mt-5 w-tablet-100 mt-tablet-20px"
                >
                  {
                    getTranslation("download-pdf", "", true)
                  }
                </SecondaryButtonLink>
              </ColumnFour>
            </Row>
            <Row>
              <CardColumn>
                <StyledCard>
                  {/* <StaticImage
                    src="../../svgs/newDesigns/settingsFolder.svg"
                    alt="workspace"
                    layout="constrained"
                    className="mt-auto mb-auto d-tablet-none"
                    placeholder="tracedSVG"
                  /> */}
                  <SettingsFolder />
                  <h5>{
                      getTranslation("real-life-projects", "", true)
                    }</h5>
                  <p>
                    {
                      getTranslation("real-life-project-description", "", true)
                    }
                  </p>
                </StyledCard>
              </CardColumn>

              <CardColumn>
                <StyledCard>
                  {/* <StaticImage
                    src="../../svgs/newDesigns/community.svg"
                    alt="workspace"
                    layout="constrained"
                    className="mt-auto mb-auto d-tablet-none"
                    placeholder="tracedSVG"
                  /> */}

                  <Community />
                  <h5>{getTranslation("Community", "", true)}</h5>
                  <p>
                   {
                    getTranslation("community-description", "", true)
                   }
                  </p>
                </StyledCard>
              </CardColumn>

              <CardColumn>
                <StyledCard>
                  {/* <StaticImage
                    src="../../svgs/newDesigns/elearning.svg"
                    alt="workspace"
                    layout="constrained"
                    className="mt-auto mx-auto mb-auto d-tablet-none"
                    placeholder="tracedSVG"
                  /> */}
                  <Elearning />
                  <h5>{getTranslation("world-leading-tutors", "", true)}</h5>
                  <p>
                   {
                    getTranslation("world-leading-tutors-description", "", true)
                   }
                  </p>
                </StyledCard>
              </CardColumn>
            </Row>
          </FixedContainer>
        </Container>
      </div>
    </div>
  )
}

export default GameChangerSection
